import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent, useRef } from 'react';
import { isTablet } from 'react-device-detect';
import { Tween } from 'react-gsap';
import { Controller, Scene } from 'react-scrollmagic';
import Constants from '../../../util/constants';
import navbarStyles from '../navbar/navbar.component.module.scss';
import styles from './logo.component.module.scss';

type LogoProps = {
  startSmall?: boolean;
}

const Logo: FunctionComponent<LogoProps> = ({ startSmall }) => {
  const staticLabel = useRef<HTMLParagraphElement>(null);

  return (
    <Tween>
      <Controller>
        <Scene
          offset={Constants.scrollAnimation.offset}
          duration={Constants.scrollAnimation.duration}
        >
          {(progress: number) => {
            if (startSmall) {
              progress = 100;
            }

            return (
              <Tween
                to={{
                  left: isTablet ? navbarStyles.borderMobile : navbarStyles.border,
                  top: `${isTablet ? 0 : -20}px`,
                  transform: 'translate(0, 0) scale(0.7)'
                }}
                ease={Constants.scrollAnimation.ease}
                totalProgress={progress}
                paused
              >
                <div className={styles.logo}>
                  <a href="/">
                    <div className={styles.small}>
                      <Tween
                        to={{
                          left: '0px',
                          top: '0px',
                          transform: 'translate(0, 0)'
                        }}
                        ease={Constants.scrollAnimation.ease}
                        totalProgress={progress}
                        paused
                      >
                        <p>Celestial</p>
                      </Tween>
                      <Tween
                        to={{
                          top: '45px',
                          transform: 'translate(-20vh, 0) scale(1.6)'
                        }}
                        ease={Constants.scrollAnimation.ease}
                        totalProgress={progress}
                        paused
                      >
                        <p ref={staticLabel}>Static</p>
                      </Tween>
                    </div>
                    <Tween
                      to={{ opacity: '0' }}
                      ease={Constants.scrollAnimation.ease}
                      totalProgress={progress}
                      paused
                    >
                      <p className={styles.games}>Games</p>
                    </Tween>
                    <Tween
                      to={{ opacity: '0', visibility: 'hidden' }}
                      ease={Constants.scrollAnimation.ease}
                      totalProgress={progress * 3}
                      paused
                    >
                      <div className={styles.chevrons}>
                        <FontAwesomeIcon icon={faChevronDown} className={styles.icon}/>
                        <FontAwesomeIcon icon={faChevronDown} className={styles.icon}/>
                      </div>
                    </Tween>
                  </a>
                </div>
              </Tween>
            );
          }}
        </Scene>
      </Controller>
    </Tween>
  );
};

export default Logo;
