const Config = {
  siteUrl: 'https://celestial-static-games.de',
  siteTitle: 'Celestial Static Games',
  wordpress: {
    baseUrl: 'http://backend.celestial-static-games.de/',
    uploadsUrl: 'http://backend.celestial-static-games.de/wp-content/uploads/',
    auth: {
      header: 'Basic YmxvZy1iYWNrZW5kOmJ2Jk5CTV50TUpmMyN1TmU=',
      user: 'blog-backend',
      pass: 'bv&NBM^tMJf3#uNe'
    }
  },
  disqus: {
    shortname: 'celestial-static-games',
    getConfig: (url, id, title) => ({
      url,
      identifier: id,
      title,
      language: 'en_US'
    })
  },
  posts: {
    filter: {
      debounceMs: 0
    }
  },
  images: {
    'archive': '/images/archive-background.jpg',
    'imprint': '/images/legal-background.jpg',
    'privacy': '/images/privacy-background.jpg'
  }
};

module.exports = Config;