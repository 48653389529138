import { AnchorLink } from 'gatsby-plugin-anchor-links';
import React, { FunctionComponent } from 'react';
import { Controller, Scene } from 'react-scrollmagic';
import styles from './navbar.component.module.scss';

type NavbarLink = {
  title: string;
  anchor: string;
}

const Navbar: FunctionComponent = () => {
  const links: NavbarLink[] = [
    {
      title: 'About',
      anchor: '/#about'
    },
    {
      title: 'In my words',
      anchor: '/#in-my-words'
    },
    {
      title: 'Contact',
      anchor: '/#contact'
    }
  ];

  return (
    <Controller>
      <Scene
        offset={500}
        classToggle={styles.small}
      >
        {() => (
          <div>
            <nav className={styles.navbar}>
              <ul>
                {links.map(link => (
                  <li key={link.title} className={styles.item}>
                    <AnchorLink to={link.anchor} title={link.title} />
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        )}
      </Scene>
    </Controller>
  );
};

export default Navbar;
