import { faInstagramSquare, faTwitterSquare } from '@fortawesome/free-brands-svg-icons';
import { faAt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import React, { FunctionComponent } from 'react';
import Obfuscate from 'react-obfuscate';
import globalStyles from '../../styles/global.module.scss';
import styles from './contact.component.module.scss';

const Contact: FunctionComponent = () => {
  const year = new Date().getFullYear();

  return (
    <div className={`${globalStyles.content} ${styles.contact}`} id={'contact'}>
      <div className={styles.links}>
        {/* @ts-ignore */}
        <Obfuscate
          email="contact@celestial-static-games.de"
          headers={{
            subject: 'Hey there!'
          }}>
          <FontAwesomeIcon icon={faAt} className={`${styles.icon} ${globalStyles.scale}`}/>
        </Obfuscate>
        <a href={'https://twitter.com/celestialstatic'} target={'_blank'}>
          <FontAwesomeIcon icon={faTwitterSquare} className={`${styles.icon} ${globalStyles.scale}`}/>
        </a>
        <a href={'https://www.instagram.com/celestialstaticgamesde'} target={'_blank'}>
          <FontAwesomeIcon icon={faInstagramSquare} className={`${styles.icon} ${globalStyles.scale}`}/>
        </a>
      </div>
      <div className={styles.footer}>
        © {year} Celestial Static Games&nbsp;|&nbsp;
        <Link to={'/legal'} target={'_blank'}>Legal</Link>
        &nbsp;|&nbsp;
        <Link to={'/privacy'} target={'_blank'}>Privacy</Link>
      </div>
    </div>
  );
};

export default Contact;
