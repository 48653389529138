import { PageContext } from 'gatsby/internal';

export default {
  scrollAnimation: {
    ease: 'power1.easeOut',
    offset: 50,
    duration: 700
  }
};

export type StaticPageProps = {
  pageContext: PageContext
};